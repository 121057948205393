.about-ctr {
    margin-bottom: 50px;
}

.about-section {

    background-color: var(--background);
    /* background-color: var(--navy-blue); */
    /* color: white; */
    box-shadow: var(--shadow);
    margin: 25px;
    border-radius: 25px;

}

.section-header-text {
    /* text-shadow: 0 0.3rem 0.4rem rgba(0,0,0,0.24),0 17px 50px rgba(0,0,0,0.19); */
    color: var(--navy-blue)
}

.about-section-text {
    gap: 15px;
    padding: 25px;
    text-align: left;
    margin-right: auto;
    color: var(--shadow-box)
}

.about-section-text-founding-devs {
    gap: 15px;
    padding: 20px;
}

.about-section span {
    font-style: italic;
    /* color: rgba(167, 133, 89, 1.0); */
}

.about-icon-div {
    /* padding: 50px; */
    padding: 30px;
    /* background-color: var(--shadow-box); */
    background-color: lightgrey;
    border-radius: 50%;
    margin: 10px 0px 10px 15px;
    box-shadow: var(--shadow);
}

.about-icon {
    font-size: 50px;
    /* color: black; */
    color: var(--shadow-color);
}

.contact-links {
    /* background-color: whitesmoke; */
    background-color: var(--shadow);
    padding: 5px;
    border-radius: 25px;
    flex-direction: row;
    box-shadow: var(--shadow)
}

.contact-links a {
    margin-right: auto;
    margin-left: auto;
}

.our-team {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    /* border: 1px solid red; */
    width: 100%;
}

.our-team .profile {
    margin-top: 0px;
    padding-top: 0px;
    /* margin-left: 4rem; */
    /* margin-right: 4rem; */
    /* padding-bottom: 1rem; */
}


/* had to offset given difference in name length */
/* .our-team {
    margin-left: 4rem;
} */

.our-team .profile .about-section-text {
    width: 200px;
}

.headshot {
    width: 100px;
    height: auto;
    border-radius: 50%;
    box-shadow: var(--shadow);
    margin-right: auto;
    margin-left: auto;
}
/* 
.banner { */

    /* MOVING BANNER TO APP.CSS; prior css below: */

    /* background-color: var(--pale-blue); */
    /* background-color: var(--shadow); */
    /* padding: 25px; */
    /* gap: 10px; */
    /* margin-top: 0; */
    /* border: 1px solid rgb(169, 169, 207); */
/* } */

.social-media-icon-div {
    display: flex;
    justify-content: space-evenly;
    /* background-color: var(--navy-blue); */
    background-color: var(--background);
    width: 4rem;
    margin-right: auto;
    margin-left: auto;
    border-radius: 2rem;
}

.social-media-icon {
    background-color: var(--background);
    color: black;
    transition: 0.15s;
}

.social-media-icon:hover {
    color: var(--navy-blue);
    transition: 0.15s;
}

.navy-background {
    display: flex;
    flex-direction: column;
    /* background-color: var(--navy-blue); */
    background-color: var(--shadow);
    border-radius: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--background);
    margin-bottom: 1rem;
    
}



@media only screen and (max-width: 900px) {

    .about-icon-div
    {
        display: none;
    }
  }
  

@media only screen and (max-width: 500px) {
    .our-team {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 15px;

    }
    .headshot {
        width: 130px;
    }
    .about-section-text h2 {
        font-size: 20px;
    }
    /* .profile {
        width: 75vw;
    } */
  }


