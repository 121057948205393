/* .simulation-ctr {
    width: 90vw;
    max-width: 1200px;
    height: 85vh;
    max-height: 900px;
    margin: auto auto;
    border: 2px solid red;

} */


/* .next-button {
    position: absolute;
    bottom:0;
} */

.gaps {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.gaps-space-evenly {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
}

.justify-left {
    margin-left: 10px;
    justify-content: left;
}
