.part-two-sim-ctr {
    padding-top: 20px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}


.width-too-small {
    display: none
}

.sim2-grid {
    display: grid;
    background-color: var(--background);
    border: 2px solid black;
    width: 90vw;
    max-width: 1200px;
    height: 85vh;
    max-height: 900px;
    margin: auto auto;
    border-radius: 15px;
    grid-template-columns: 1fr 1.50fr 1fr;
    grid-template-rows: 1.4fr 1.5fr 0.2fr;
}

.sim2-grid > div {
    border: 0.5px solid black;
}

.sim2-grid .toggle-window {
    grid-column: 1 / span 3;
    border: none;
}

.sim2-grid .orders {
    grid-column: 1 / span 1;
}

.sim2-grid .orders {
    border: 1px solid var(--navy-blue)
}

.sim2-grid .avatar {
    border: 1px solid var(--navy-blue)
}

.sim2-grid .model {
    border: 1px solid var(--navy-blue)
}

.sim2-grid .logo {
    grid-column: 1 / span 1;
}
.sim2-grid .bottom-nav {
    z-index: 1;
    grid-column: 1 / span 3;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 2px solid var(--shadow-color)
}

.bottom-nav {
    display: flex;
    justify-content: space-evenly;
    z-index: 1001;
}

.data-table th {
    font-size: 12px; 
    /* previously font size was 12, but easier if smaller */
}

.data-table {
    height: 100%;
    width: 1100px;
    /* WIDTH MUST BE AT LEAST 1100 PX! */
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    /* width: 100%; */
    /* width: fit-content; */
    background-color: whitesmoke;
}

.data-table td th {
    border-left: solid black 1px;
}

.data-table td {
    min-width: 35px;
    font-size: 11px;
}

.table-ctr {
    position: sticky;
    overflow: visible;
    left: auto;
    height: 100%;
    /* width: 1100px; */
    width: 1100px;
    /* WIDTH MUST BE AT LEAST 1100 PX! */
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    /* width: 100%; */
    /* width: fit-content; */
    background-color: whitesmoke;
}

.data-table .new-table-header {
    position: sticky;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;;
    left: 0px;
    z-index: 2;
    background-color: whitesmoke;
    padding-right: 0.5rem;
}

.data-table .new-table-header-top {
    position: sticky;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;;
    z-index: 2;
    left: 0px;
    background-color: whitesmoke;
    padding-right: 0.5rem;
}

.data-table .new-table-header-top-2 {
    position: sticky;
    overflow: visible;
    margin-right: 0px;
    margin-left: 0px;;
    left: 0px;
    z-index: 2;
    background-color: whitesmoke; 
    padding-right: 0.5rem;
}


.table-side-box-left-in-sim {
    width: 2.5rem;
    background: whitesmoke;
    height: 100%;
    border: none;
}


.upper-box {
    max-height: 2rem;
    width: 100%;
    background: green;
    height: 100%;
    border: none;
}

.lower-box {
    height: 1rem;
    /* background: yellow; */
}

.model-ctr {
    background-color: rgb(209, 212, 212);
}

.model {
    background-color: rgb(209, 212, 212);
    
}


.data-table-outer-container-in-sim {
    display: flex;
    justify-content: center;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.part-two-sim-ctr h2 {
    width: 100%;
    padding: 15px;
    /* margin-top: auto; */
    background-color: var(--navy-blue);
    color: white;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
}

.part-two-sim-ctr h2, .part-two-sim-ctr h3, .part-two-sim-ctr h4{
    background-color: var(--navy-blue);
    color: white;
    width: 100%;
    padding: 5px;
    /* border-top-right-radius: 15px;
    border-top-left-radius: 15px; */
}

.sim2-grid .orders-form-ctr {
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* background-color: whitesmoke; */
}

.orders-form-ctr {
    margin-top: -1rem;
    padding-bottom: 2rem;
    margin-bottom: 0rem;
}

.active-orders-text {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.active-orders-text li {
    margin-top: 0.2rem;
}



.active-orders-ctr {
    background-color: whitesmoke;
    width: 100%;


}

.active-orders-ctr h2 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


.pertinent-data {
    border-top-left-radius: 15px;
}

.q4-explanation {
   position: relative;
   left: 3rem;
   margin: 0rem;
   /* border: 1px solid red; */
   /* width: 20rem; */
}

.star {
    position: relative;
    left: 0rem;
    width: 1rem;
}

.model-ctr h4, .active-orders-ctr h4, .avatar h4 {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}


.avatar {
    background-color: rgba(204, 209, 209, 1.0);
}

.avatar iframe {
    border-left: 2px solid var(--navy-blue)
}

iframe {
    border: none;
}



.orders-form-ctr form {
    padding-top: 1rem;
    margin-bottom: -1rem;
    /* display: flex;
    justify-content: space-around;
    gap: 2rem; */
    /* display: grid;
    justify-content: space-evenly;
    grid-template-columns: 1.5fr 1.5fr; */
    gap: 5px;
    /* margin-left: 2rem; */
}



.orders-form-ctr h2, h3, h4 {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */

}

.top-of-orders-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: whitesmoke; */
    /* width: 30rem; */
    margin-left: 0rem;
    margin-right: 2rem;
}

.top-of-orders-form input {
    display: flex;
    flex-direction: flex-end;
    /* justify-content: center; */
    width: 5rem;
    /* margin-bottom: 2rem; */
}

.range-indicator {
    font-size: 13px;
}

.orders-title {
    font-size: 16px;
    margin-top: 1rem;
}

.orders-title-2 {
    font-size: 16px;
    margin-top: 1rem;
    margin-left: 2rem;
}

.top-of-orders-form label {
    display: flex;
    flex-direction: flex-end;
    /* justify-content: center; */
    /* background-color: blue; */
    width: 1rem;
    /* margin-left: 2rem; */
    margin-right: 0.5rem;
    margin-bottom: 3rem;
}

.bottom-of-orders-form {
    /* background-color: whitesmoke; */
    padding: 0.3rem;
}

.bottom-of-orders-form label {
    width: 10rem;
    margin-right: 0.3rem;
    font-size: 14px;
    /* margin-right: -2rem; */
    /* display: flex; */
    /* flex-direction: column; */
    /* background-color: red; */
    /* margin-left: 1rem; */
}

.adjacent {
    display: flex;
    direction: column;
}


.top-and-bottom-orders-form {
    display: flex;
    justify-content: center;
}

.orders-form-ctr form label {
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    font-weight: 500;
}



.logo-ctr-2 {
    display: flex;
    margin-right: auto;
    margin-left: -2.5rem;
    margin-top: 0.3em;
    /* height: auto; */
    /* background-color: red; */
    height: 2.5rem;
}

.iframe2-again {
    margin-right: auto;
    margin-left: 10px;
    margin-top: -0.1em;
}  



.button-gaps2 {
    display: flex;
    justify-content: space-evenly;
    margin-right: 2em;
    z-index: 100;
    /* this puts the buttons on top of the timer, fixing that glitch */
}

.active-orders-form {
    /* background-color: rgb(207, 205, 205) */
    background-color: rgb(209, 212, 212);
    border-right: 2px solid var(--navy-blue)
}

.clock-size {
    width: 20px;
    margin-top: -2px;
    margin-right: 1px;
    margin-left: 1px;
}

.show-current-tonicity-values {
    visibility: hidden;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
}


.new-table-header {
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top: none;
    margin-left: 1rem;
    box-shadow: -1px 0px inset black;
}

.new-table-header-left {
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top: none;
    margin-left: 1rem;
    padding-right: 1rem;
}

.new-table-header-top-left {
    border-right: none;
    border-left: none;
    margin-left: 1rem;
}

.new-table-header-top {
    border-right: none;
    border-left: none;
    margin-left: 1rem;
    box-shadow: -1px 0px inset black;
}

.new-table-header-top-2 {
    border-right: none;
    border-left: none;
    border-bottom: none;
    margin-left: 1rem;
    box-shadow: -1px 0px inset black;
    /* margin-right: 3rem; */
    /* padding-left: 1rem; */
}

.new-table-header-top-2-left {
    border-right: none;
    border-left: none;
    border-bottom: none;
    margin-left: 1rem;
    /* margin-right: 3rem; */
    /* padding-left: 1rem; */
}

.order-grouping {
    display: flex;
    flex-direction: row;
    margin-left: 0rem;
    margin-right: 0rem;
}


.data-table .bg-grey {
    background-color: lightgrey;
}

.data-table .bg-blue {
    background-color: rgb(225, 225, 244);
}

.data-table .bg-yellow {
    background-color: rgb(246, 246, 234);
}

.data-table .bg-red {
    background-color: rgb(251, 231, 231);
}


@media screen and (max-width: 910px) {

    .iframe2-again {
        display: none;
    }
}

@media screen and (max-width: 875px) {
 
    .iframe2-again {
        display: none;
    }
    .avatar {
        display: none;
    }
    .data-table th, td {
        font-size: 10px; 
    }
    /* .data-table {

    } */
    .sim2-grid {
        display: grid;
        background-color: var(--background);
        border: 2px solid black;
        width: 90vw;
        max-width: 1200px;
        height: 85vh;
        max-height: 900px;
        margin: auto auto;
        border-radius: 15px;
        grid-template-columns: 0.7fr 1.3fr;
        grid-template-rows: 1.4fr 1.5fr 0.2fr;
    }
}

@media screen and (max-width: 800px) {

    /* .range-indicator {
        display: none;
    } */

}



@media screen and (max-height: 815px) {

    .data-table th, td {
        font-size: 10px; 
    }
    /* .data-table {

    } */
    .avatar {
        display: none;
    }
    .sim2-grid {
        display: grid;
        background-color: var(--background);
        border: 2px solid black;
        width: 90vw;
        max-width: 1200px;
        height: 85vh;
        max-height: 900px;
        margin: auto auto;
        border-radius: 15px;
        /* grid-template-columns: 1fr 1.50fr 1fr; */
        grid-template-columns: 0.7fr 1.3fr;
        grid-template-rows: 1.4fr 1.5fr 0.2fr;
    }
}

@media screen and (max-height: 770px) {

    .model {
        display: none;
    }
    .avatar {
        display: none;
    }
    .data-table th, td {
        font-size: 9px; 
    }
    /* .data-table {

    } */
    .sim2-grid {
        display: grid;
        background-color: var(--background);
        border: 2px solid black;
        width: 90vw;
        max-width: 1200px;
        height: 85vh;
        max-height: 900px;
        margin: auto auto;
        border-radius: 15px;
        grid-template-columns: 1fr;
        grid-template-rows: 1.4fr 1.5fr 0.2fr;
    }
}


@media screen and (max-width: 680px) {

    .sim2-grid {
        display: none;
    }

    .width-too-small {
        display: block
    }

}




