.index-table th, td {
    border: 1px solid black;
}


.index-table {
    width: 90vw;
    max-width: 1000px;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    box-shadow: var(--shadow);
    border-radius: 25px;
    border: none;
    /* border: 2px solid red; */
}

.index-table th, .index-table td {
    background-color: var(--background);
    /* border: 2px solid red; */
    border: 1px solid var(--shadow-color);
    padding: 10px;
    border-right: none;
    border-left: none;
    /* border-top: none; */
    border-bottom: none;
}

.index-table tr:last-child td:last-child {
    border-bottom-right-radius: 25px;
    border-right: none;
    border-bottom: none;
}

.index-table tr:last-child td:first-child {
    border-bottom-left-radius: 25px;
    border-left: none;
    border-bottom: none;
}

.index-table th:first-child {
    border-top-left-radius: 25px;
    border-left: none;
    border-top: none;
    border-bottom: var(--shadow-color);
}

.index-table th:last-child {
    border-top-right-radius: 25px;
    border-right: none;
    border-top: none;
    border-bottom: var(--shadow-color);
}

.index-table th {
    border-bottom: var(--shadow-color);
}

.index-table tr:last-child {
    border-bottom: none;
}
.index-table tr:first-child th {
    border-top: none;
}

.cases-index-ctr button {
    margin: 20px;
}

th.delete-cell, td.delete-cell {
    width: 100px;
}

td.delete-cell > button {
    padding: 5px;
    font-size: 12px;
    margin: 0;
}

/* .case-id-link {
    display: flex;
    justify-content: center;
    color: navy;
    font-size: 14px;
} */

.new-case-div {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 90vw;
    /* box-shadow: 4px 5px 1px rgb( 195, 169, 126); */
    box-shadow: var(--shadow);
    border-radius: 25px;
    background-color: var(--background);
    padding: 5px;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.new-case-div-text {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 90vw;
    box-shadow: var(--shadow);
    border-radius: 25px;
    background-color: var(--background);
    padding: 20px;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
}

@media only screen and (max-width: 700px) {
    .index-table th, td {
        padding: 2px;
    }
}