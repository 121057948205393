footer {

    background-color: var(--background);
    /* background-color: var(--shadow-color); */

    /* background-color: var(--shadow); */

    color: var(--lightest-color);

    margin-top: auto;
    bottom: 0;
    width: 100vw;
    height: 3vh;
    gap: 15px;
    position: fixed;
    z-index: 100;
    /* border-top: 3px solid rgb( 195, 169, 126); */
    /* border-top: 3px solid rgb(70, 73, 93); */
    box-shadow: 0 0.3rem 0.4rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}


.social-media-icon {
    height: 0.7rem;
    color: var(--navy-blue)
    /* color: var(--background); */
}

.footer-font {
    color: var(--navy-blue)
}

.footer-contact-info {
    display: flex
}

.footer-contact-info:hover {
    size: 1em
}