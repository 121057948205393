body {
    background-color: whitesmoke;
}



.all-feedback-lists {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-right: auto;
    margin-left: auto;
}

.improvement-list {
    grid-column: 2;
    grid-row: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    background: var(--lightest-color);
    margin-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
}


.success-list {
    grid-column: 1;
    grid-row: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    background: rgb(208, 235, 245);
    margin-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 1rem;
    color: var(--shadow-box)
}

.shadow-font {
    color: var(--shadow-box)
}


.success-list-contents {
    grid-column: 1;
    grid-row: 2;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.5rem;
    width: 100%;
}

.improvement-list-contents {
    grid-column: 2;
    grid-row: 2;
    padding-left: .3rem;
    padding-right: 0.3rem;
    padding-top: 0.5rem;
    width: 100%;
}

.bullet-points {
    margin-top: 0.5rem;
}

.button-for-next {
    background-color: white;
    position: sticky;
    overflow: visible;
    bottom: 40px;
}


.bottom-bar-buttons {
    display: flex;
    margin-left: auto;
}

.bottom-button-container {
    position: fixed;
    width: 100vw;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
    z-index: 1;
    bottom: 0;
    /* box-shadow: 0 0.3rem 0.4rem rgba(0,0,0,0.24),0 17px 50px rgba(0,0,0,0.19); */
    /* background-color: var(--lightest-color); */
    background-color: whitesmoke
  }
  

  .all-data-table-for-feedback  {
        /* margin-top: -1rem; */
        overflow: scroll;
        /* display: flex;
        justify-content: center; */
        margin-right: 2rem;
        margin-left: 2rem;
  }

  .center-div {
    display: flex;
    justify-content: center;
  }
  