.user-information-ctr {
    background-color: var(--background);
    box-shadow: var(--shadow);
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
    padding: 10px;
    /* border: 1px solid black; */
    gap: 20px;
    border-radius: 25px;
}

.user-information-ctr button {
    margin: 20px;
}

/* .exclaimation-icon {
    color: maroon;
    font-size: 24px;
    display: flex;
    align-items: flex-start;
} */

.guest-info {
    font-style: italic;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: maroon;
}