* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 


:root {

  /*  original color scheme below: */

  /* --baby-blue: rgba(150, 194, 222, 1.0);
  --sky-blue: rgba(50, 152, 219, 1.0);
  --background: rgb(159, 175, 202);
  --navy-blue: rgb(41, 76, 116);
  --shadow-color: rgb(70, 73, 93);
  --shadow: 0px 3px 1px rgb(70, 73, 93);
  --pale-blue: rgb(223, 223, 236); */

    /*  original color scheme above: */


  /* alternative color scheme - ocean colors: */

      /* background: rgb(219, 217, 227);
      background: rgb(173, 180, 191);
      background: rgb(109, 121, 141);
      background: rgb(83, 92, 117);
       background: rgb(50, 62, 90); */

  /* new version below mixing the two */

  /* new variable name below: */

  --baby-blue: rgba(150, 194, 222, 1.0);


  --sky-blue: rgb(28, 37, 52);
    /* now close to shadow color; this is activated with button hover*/

  --lightest-color: rgb(219, 217, 227);
  /* lightest color is updated*/
  --background: rgb(173, 180, 191); 

    /* background updated */
  --navy-blue: rgb(50, 62, 90);
    /* navy blue is updated - this is now the sim page modal banners */

    --shadow: rgb(83, 92, 117);
    /* shadow color updated */

    /* --shadow-color: rgb(28, 37, 52); */
    --shadow-color: rgb(28, 30, 34);

  --pale-blue:  rgb(219, 217, 227);
  /* pale blue is updated - this is the other banners - it is now the SAME as the background, for now */


}

body {
  margin: 0;

  /* font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* background: rgb(71, 73, 93); */

  /* background: rgb(90, 91, 107); */

  /* background: rgb(223, 223, 236); */

  background: rgb(219, 217, 226);

  
  /* background: rgb(245, 245, 245); */

/* alternative color scheme - ocean colors: */
    /* background: rgb(219, 217, 227); */
     /* background: rgb(173, 180, 191); */
        /* background: rgb(109, 121, 141); */
         /* background: rgb(83, 92, 117); */
          /* background: rgb(50, 62, 90); */

  text-align: left;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
