.App {
  text-align: center;
  /* overflow: scroll; */
}
/* p {
  margin: 0 15px;
} */

.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.flex-ctr-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.flex-ctr-ctr-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 15px; */
  /* flex-wrap: wrap */
}

.flex-col-btw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-col-even {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

table, th, td {
  border-collapse: collapse;
  /* border-collapse: separate !important; */
  /* padding: 4px; */
  padding-top: 3px;
  padding-bottom: 2px;
  padding-right: 4px;
  padding-left: 4px; 
  border: 1px solid black;
}

button, input[type=submit] {

  /* original below: */
  /* background-color: var(--navy-blue);
  color: var( --lightest-color);
  padding: 7px;
  border-radius: 15px; */
  /* original above: */

  background-color: var(--navy-blue);
  color: var(--pale-blue);
  padding: 7px;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 0.15rem 0.2rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  transition: 0.3s;
}

/* #css-added */
button:hover, input[type=submit]:hover {
  background-color: var(--sky-blue);
  color: white;
  padding: 7px;
  cursor: pointer;
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

button:disabled, input[type=submit]:disabled {
  background-color: grey;
}

.gap-20 {
  gap: 20px;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items:center
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.hidden {
  display: none;
}

.banner {
  background-color: var(--shadow);
  padding: 1rem;
  /* color: var(--shadow-color); */
  text-shadow: 0 0.3rem 0.4rem rgba(0,0,0,0.24),0 17px 50px rgba(0,0,0,0.19);
  color: var(--lightest-color);
  position: fixed;
  width: 100vw;
  z-index: 10;
}

.content-below-header {
  position: relative;
  overflow: hidden;
  top: 4rem;
  padding-bottom: 4rem;
}

.bottom-button-container {
  position: fixed;
  width: 100vw;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 2rem;
  z-index: 1;
  bottom: 0;
  /* box-shadow: 0 0.3rem 0.4rem rgba(0,0,0,0.24),0 17px 50px rgba(0,0,0,0.19); */
  background-color: var(--lightest-color);
}


.gap-above {
  margin-top: 0.5rem
}

.gap-below {
  margin-bottom: 0.5rem
}

/* .tooltip-wrap {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  z-index: 5;
  bottom: 5%;
  left: 5%;
  right: 5%;
  background-color: #fff;
  padding: .5em;
  min-width: 10rem;
  border-radius: 25px;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
}
.tooltip-content > p {
  font-size: 12px;
  color: black;
}
.tool-tip-icon {
  color: red;
} */