
.edit-user-form {
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    background: var(--background);
    box-shadow: var(--shadow);
    padding: 30px;
    border-radius: 25px;
}

.edit-user-form > label {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.edit-user-form > input {
    height: 30px;

    width: auto;
    padding: 8px 10px;
    margin: 5px 0px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--dark-blue);

    /* background-color: rgba(182, 208, 217, 0.3); */
}

.edit-user-ctr h6 > span {
    color: maroon;
    font-style: italic;
}