nav {
    /* background-color: var(--background); */


    background-color: var(--shadow-color);
    /* this was changed from background for now */

    /* background-color: rgb(253, 231, 194); */
    
    height: 6vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    z-index: 20;
    /* box-shadow: 0px 3px 1px rgb( 195, 169, 126); */
    box-shadow: var(--shadow)
}

.nav-link {
    /* padding: 10px; */

    padding: 7px;
    /* background-color: var(--navy-blue); */
    background-color: var(--shadow-color);

}

.link {
    margin: 0 10px;
    /* padding: 10px; */
    padding: 5px;
}

.link-nav-bar {
    margin: 0 10px;
    /* padding: 10px; */
    padding: 5px;
    background-color: var(--shadow-color);
}

.link-nav-bar:hover {
    cursor: pointer;
}

.nav-link-button {
    margin: 0 10px;
    /* padding: 10px; */
    padding: 5px;
    background-color: var(--shadow-color);
    border: none;
    transition: 0.4s;
    box-shadow: none
}

.nav-link-button:hover {
    margin: 0 10px;
    /* padding: 10px; */
    padding: 5px;
    background-color: var(--shadow-color);
    border: none;
    border-radius: 100%;
    font-size: 0.9em;
    box-shadow: none;
    /* cursor: pointer; */
}


.logout {
    margin-left: auto;
}

.profile-button h3, h4, p {
    display: inline;
}

.profile-button p {
    display: inline;
    padding-left: 5px;
}
