.feedback-ctr {
    gap: 20px;
    text-align: left;
}

.feedback-ctr h1, h2, h3, h4, h5, h6 {
    text-align: center;
}

.feedback {
    border-top-right-radius: 15px;
}

.feedback-ctr ul, li {
    text-align: left;
    margin: 0;
    padding: 0;
}

.part-one-sim .toggled-window {
    border-top-right-radius: 15px;
}

.feedback-ctr table {
    width: 100%;
}

.comparison-table {
    width: 100%;
    /* margin-right: auto;
    margin-left: 1rem; */

    left:0;
    right:0;
}

.comparison-table-div {
    margin-left: 1rem;
    margin-right: 1rem;
}



button.return {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    height: 2.5rem;
    width: 100%;
    position: sticky;
    bottom: 0px;
    z-index: 10;
}

.return {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    height: 2.5rem;
    width: 100%;
    position: sticky;
    bottom: 0px;
    z-index: 10;
}