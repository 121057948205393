
.title-summary-page {
    display: flex;
}

.sub-banner {
    background-color: var(--shadow);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: var(--lightest-color);
    padding: 5px;
    width: 100%;
    z-index: 2;
}

.sub-banner2 {
    background-color: rgb(121, 120, 120);
    color: whitesmoke;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 5px;
    width: 100%;
    z-index: 2;
}

.case-information {
    background-color: var(--background);
    /* padding: 15px; */
    width: 90vw;
    margin: 0 auto;
    border-radius: 15px;
    padding-bottom: 15px;
}

.case-information-table {
    /* margin-top: -1.4rem; */
    width: 85vw;
    border: none;
    background-color: whitesmoke;
    border: 5px solid var(--background);
    font-size: large;
    margin-right: auto;
    margin-left: auto;
}

.medical-record-grid-1, .medical-record-grid-2 {
    background-color: whitesmoke;
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
}

.triage-note {
    background-color: whitesmoke;
    width: 85vw;
    margin: 10px;
    border-radius: 15px;
    padding: 10px;
}

.part-one-summary, .part-two-summary {
    background-color: var(--background);
    margin: auto auto;
    margin-top: 15px;
    width: 90vw;
    border-radius: 15px;
}

.margins-for-case-table {
    width: 70vw;
    margin-right: auto;
    margin-left: auto;
}

.comparison-ctr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    width: 100%;
}

.case-summary-ctr .feedback {
    padding: 12px;
}

.case-summary-ctr table {
    width: 100%;
}

.yours {
    /* width: 100%; */
    width: 40vw;
    background: whitesmoke;
    border-radius: 15px;
    /* padding: 25px; */
}

.ours {
    width: 40vw;
    background-color: var(--lightest-color);
    border-radius: 15px;
    /* padding: 25px; */
}

.yours h4, .ours h4 {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgb(121, 120, 120);
    color: whitesmoke;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.padding-summary-page {
    margin-left: 1rem;
    margin-right: 1rem;
}

.comparison-ctr .list {
    padding: 25px;
}

.case-summary-ctr .improvement-list {
    padding: 0px;
}
.case-summary-ctr .improvement-list-contents {
    padding: 15px;
}
.case-summary-ctr .success-list {
    padding: 0px;
}
.case-summary-ctr .success-list-contents {
    padding: 15px;
}

.compare-impressions-ctr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.your-impression {
    background-color: whitesmoke;
    width: 100%;
    padding-bottom: 15px;
    margin: 15px;
    border-radius: 15px;
}
.our-impression {
    background-color: var(--lightest-color);
    width: 100%;
    padding-bottom: 15px;
    margin: 15px;
    border-radius: 15px;
}


.data-table-container-padding {
    display: flex;
    flex: flex-start;
    position: relative;
    left: 0;
    width: 100%;
    /* padding-left: 1rem;
    padding-right: 1rem; */
    margin-right: auto;
    margin-left: auto;
    margin-top: -1rem;
    /* overflow-x: hidden; */
    overflow: scroll;
    z-index: 1;
}

.data-table-outer-container {
    display: flex;
    justify-content: center;

}

.z-index-2 {
    z-index: 2;
}

.table-side-box-left {
    width: 2rem;
    background: var(--background);
}

.table-side-box-right {
    width: 2rem;
    background: var(--background);
}


.table-top-box {
    height: 3rem;
    margin-bottom: -2rem;
    background: var(--background);
    z-index: 2;
}

.spacer-down {
    height: 2rem;
}

/* .banner {
    display: none;
} */