.part-one-sim {
    padding-top: 20px;
}

.sim-grid > div {
    border: 0.5px solid black;
    z-index: 2;
    /* needs z-index 2 for safari in order to cover the logo corner */
}

.sim-grid {
    /* background-color: rgba(176, 188, 199, 1.0); */
    background-color: var(--background);
    border: 2px solid black;
    width: 90vw;
    max-width: 1200px;
    height: 85vh;
    max-height: 900px;
    margin: auto auto;
    display: grid;
    text-align: 'center';
    grid-template-columns: 0.8fr 2.2fr 2.2fr;
    grid-template-rows: 1fr 3fr 0.5fr;
    border-radius: 15px;
}


.sim-grid .side-bar {
    /* grid-row: 1 / span 2; */
    grid-row: 1 / span 3;
    border-top-left-radius: 15px;
    /* grid-column: 1 / span 2; */
    border: none;
}

.side-bar {
    display: flex;
}

.sim-grid .logo-ctr {
    grid-column: 1 / span 1;
    border-top-left-radius: 15px;
}

.sim-grid .flex-box {
    grid-column: 2 / span 2;
    border-top-right-radius: 15px;
}

.sim-grid .sim-window {
    grid-column: 2 / span 2;
    height: 100%;
    align-items: center;
    border-left: 2px solid black;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.sim-grid .bottom-bar {

    grid-column: 2/ span 2;
    /* grid-column: 1/ span 3; */
    border: none;
    border-bottom-right-radius: 15px;
    display: flex;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-evenly;
}

.bottom-bar-buttons {
    display: flex;
    margin-left: auto;
}

.right-gap {
    margin-right: 1rem;
}


.sim-grid .toggled-window {
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;
    border: 2px solid black;
    box-shadow: outset 0.5em 0.5em black;
}

.sim-grid .feedback {
    grid-column: 2/ span 2;
    grid-row: 1 / span 2;
    border: 2px solid black;
    box-shadow: outset 0.5em 0.5em black;
}

.iframe {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(208, 212, 212, 1.0);
}

.side-bar .icon {
    /* font-size: 75px; */
    font-size: 60px;
    /* color: white; */
    /* background-color: black; */
    /* border: 2px solid black; */
    /* border-radius: 50%;
    padding: 5px; */
}

.side-bar button {
    border-radius: 50%;
    /* background-color: white; */
    /* padding: 0; */
}

.med-hx-ctr {
    /* border: 2px solid red; */
    width: 100%;
}

.chat-log-ctr {
    /* border: 2px solid red; */
    display: flex;
    flex-grow: 100;
}



.logo-ctr {
    border-bottom-right-radius: 15px;
    /* width: 100%; */
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-right: -12rem;
    overflow: visible;
    
}

.logo-ctr:last-of-type {
    margin-top: auto;
  }

.iframe2 {
    /* width: 100%; */
    display: flex;
    height: 100px;
    padding: 0;
    margin: 0;
    /* background-color: rgba(208, 212, 212, 1.0); */
    background-color: var(--background);
}


.interview-ctr {
    padding: 10px;
    background-color: whitesmoke;
    width: 100%;
    justify-self: center;
    border-top-right-radius: 15px;
    /* border: 2px solid red */
    border-left: 2px solid black;
}

button.return {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    height: 2.5rem;
    width: 100%;
    position: sticky;
    bottom: 0px;
    z-index: 10;
}

.return {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    height: 2.5rem;
    width: 100%;
    position: sticky;
    bottom: 0px;
    z-index: 11;
}

button.submission-button {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    height: 2.5rem;
    width: 100%;
    position: sticky;
    overflow: visible;
    margin-bottom: 0px;
    z-index: 10;
    bottom: 0px
}

.submission-button {
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    background-color: black;
    height: 2.5rem;
    width: 100%;
    overflow: visible;
    z-index: 10;
    bottom: 0px
}

.submission-button:hover {
    background-color: green;
}

.two-buttons {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-items:auto;
    margin-top: auto;
    border-radius: 0px;
    /* adding below here */
    /* height: 2.5rem; */
    width: 100%;
    gap: 0px;;
    position: sticky;
    bottom: 0px;
    z-index: 11;
}

.sim-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* justify-content: space-between; */
    align-items: center;
    gap: 15px;
    flex: 1;
    width: 100%;
    /* width: 71.75vw; */
    /* max-width: 956px; */
    text-align: center;
    background-color: whitesmoke;
    /* border: 1px solid red; */
}

.sim-modal h2{
    background-color: var(--navy-blue);
    color: white;
    width: 100%;
    padding: 15px;
    border-top-right-radius: 15px;
    /* flex: 0 0 100%;
    flex-shrink: 1;
    flex-grow: 0; */
}

.labs-tables-ctr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid red; */
    width: 100%;
}

.impression-plan-ctr textarea {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.impression-plan-ctr input {
    margin: 10px;
    width: auto;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    
}

button.toolbar, .next-button {
    font-size: 16px;
}

/* .phys-exam-ctr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
} */

.gap-down {
    margin-bottom: 0.5rem
}

.button-gaps {
    display: flex;
    margin-top: auto;
}

.text-left-align {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}

.medical-record-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.medical-record-grid-1 {
    grid-column: 1;
    margin-top: 1rem;
    margin-right: 3rem;
}

.medical-record-grid-2 {
    grid-column: 2;
    margin-top: 1rem;
    margin-right: 3rem;
}


.text-align-diag-thera {
    text-align: left;
    margin-left: 4rem;
    margin-right: 1rem;
}


.both-part-text {
    margin-right: auto;
}

.part-text {
    margin-bottom: -0.3rem;
}

.side-gaps {
    margin-left: auto;
    margin-right: auto;
}

.instructions-one-ctr ol li {
    margin-left: 40px;
}

