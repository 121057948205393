.auth-page-ctr {
    background-color: var(--background);
    box-shadow: var(--shadow);
    width: 80vw;
    height: 80vh;
    margin: 0 auto;
    transform: translateY(5vh);
    border-radius: 25px;
}

.auth-page-ctr > h1 {
    padding: 20px;
}

.login-signup-toggle-ctr {
    margin: 50px;
}

.auth-page-ctr p {
    padding: 10px;
    font-size: 17px;
}


.renal-consults-text {
    font-family: 'Josefin Sans', sans-serif;
}

.logo-ctr-auth {
    height: auto;
}


.iframe0 {
    height: 10rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.auth-buttons {
    width: auto;
}

@media only screen and (max-height: 700px) {
    .iframe0 {
        height: 4rem;
        width: 100%
    }
  }


@media only screen and (max-height: 600px) {
    .iframe0 {
        display: none;
    }
  }