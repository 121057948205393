/* .new-case-page-ctr {
    padding-top: 15px;
} */

.new-case-form {
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    background: var(--background);
    box-shadow: var(--shadow);
    padding: 30px;
    border-radius: 25px;
    box-shadow: 0 0.2rem 0.2rem 0 rgba(0,0,0,0.24), 0 0.2rem 0.2rem 0 rgba(0,0,0,0.19);
}

.new-case-form > input, .new-case-form > select {
    /* height: 30px; */

    width: auto;
    padding: 8px 10px;
    margin: 5px 0px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--dark-blue);

    /* background-color: rgba(182, 208, 217, 0.3); */
}

.new-case-form > label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}