.instructions-ctr {
    gap: 15px;
    margin-top: 15px;
}

.instructions {
    width: 90vw;
    background-color: var(--background);
    border-radius: 15px;
    padding: 15px;
    /* gap: 10px; */
}

.instructions p {
    display: block;
}
