.sign-up-form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    padding: 10px;
 

}

.sign-up-form > label {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    
}

.sign-up-form > input {
    height: 30px;


    width: auto;
    padding: 8px 10px;
    margin: 5px 0px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--dark-blue);

    /* background-color: rgba(182, 208, 217, 0.3); */
}

.space-left-form {
    margin-left: -100px;
}